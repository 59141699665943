angular.module('fingerink')
    .directive('avanzadaStamp', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'directives/widgets/avanzada-stamp.tpl.html',
            scope: {
                data: '=',
                fillable: '=',
                heightPx: '=',
                widthPx: '='
            },
            link: function ($scope, element, attrs) {
                $scope.bounds = element[0].getBoundingClientRect();
            },
            controllerAs: 'controller',
            controller: ['$rootScope', '$scope', '$uibModal', '$window', '$translate','$timeout','changeStampModal', function ($rootScope, $scope, $uibModal, $window, $translate, $timeout,changeStampModal) {
                var that = this;

                $scope.mostrar = function () {
                    if ($scope.fillable) {
                        changeStampModal.openModal().result.then(function (data) {
                            $scope.data.answer = data;
                        });
                    }
                };

                $scope.$on('call' + $scope.data.id, function (event, data) {
                    $timeout(function(){
                        $scope.mostrar();
                    });
                });


            }]
        };
    });
